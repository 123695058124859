.emojione {
  font-size: inherit;
  vertical-align: middle;
  object-fit: contain;
  margin: -0.2ex 0.15em 0.2ex;
  width: 16px;
  height: 16px;

  img {
    width: auto;
  }
}

.emoji-picker-dropdown__menu {
  position: relative;
  margin-top: 5px;
  z-index: 2;
  background: var(--dropdown-background-color);
  backdrop-filter: var(--background-filter);
  border: 1px solid var(--dropdown-border-color);
  box-shadow: var(--dropdown-shadow);
  border-radius: 5px;

  .emoji-mart-scroll {
    transition: opacity 200ms ease;
  }

  &.selecting .emoji-mart-scroll {
    opacity: 0.5;
  }
}

.emoji-picker-dropdown__modifiers {
  position: absolute;
  top: 60px;
  inset-inline-end: 11px;
  cursor: pointer;
}

.emoji-picker-dropdown__modifiers__menu {
  position: absolute;
  z-index: 4;
  top: -5px;
  inset-inline-start: -9px;
  background: var(--dropdown-background-color);
  border: 1px solid var(--dropdown-border-color);
  border-radius: 4px;
  box-shadow: var(--dropdown-shadow);
  overflow: hidden;

  button {
    display: block;
    cursor: pointer;
    border: 0;
    padding: 4px 8px;
    background: transparent;

    &:hover,
    &:focus,
    &:active {
      background: var(--dropdown-border-color);
    }
  }

  .emoji-mart-emoji {
    height: 22px;
  }
}

.emoji-mart-emoji {
  span {
    background-repeat: no-repeat;
  }
}

.emoji-button {
  display: block;
  padding-top: 5px;
  padding-bottom: 2px;
  padding-inline-start: 2px;
  padding-inline-end: 5px;
  outline: 0;
  cursor: pointer;

  img {
    filter: grayscale(100%);
    opacity: 0.8;
    display: block;
    margin: 0;
    width: 22px;
    height: 22px;
  }

  &:hover,
  &:active,
  &:focus {
    img {
      opacity: 1;
      filter: none;
      border-radius: 100%;
    }
  }

  &:focus-visible {
    img {
      outline: $ui-button-icon-focus-outline;
    }
  }
}
