.hashtag-bar {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 16px;
  gap: 6px;
  color: $darker-text-color;

  a {
    display: inline-flex;
    color: inherit;
    text-decoration: none;
    padding: 4px 12px;
    background: var(--surface-variant-background-color);
    border-radius: 4px;
    font-weight: 500;

    &:hover,
    &:focus,
    &:active {
      background: var(--surface-variant-active-background-color);
    }
  }

  .link-button {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
  }
}
