.app-body {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.animated-number {
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  position: relative;
}

.inline-alert {
  color: $valid-value-color;
  font-weight: 400;

  .no-reduce-motion & {
    transition: opacity 200ms ease;
  }
}

.icon {
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  aspect-ratio: 1;

  path {
    fill: currentColor;
  }
}

body > [data-popper-placement] {
  z-index: 3;
}

.invisible {
  font-size: 0;
  line-height: 0;
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;

  img,
  svg {
    margin: 0 !important;
    border: 0 !important;
    padding: 0 !important;
    width: 0 !important;
    height: 0 !important;
  }
}

.ellipsis {
  &::after {
    content: '…';
  }
}

.icon-with-badge {
  display: inline-flex;
  position: relative;

  &__badge {
    position: absolute;
    inset-inline-start: 9px;
    top: -13px;
    background: $ui-highlight-color;
    border: 2px solid var(--background-color);
    padding: 1px 6px;
    border-radius: 6px;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    color: $primary-text-color;
  }

  &__issue-badge {
    position: absolute;
    inset-inline-start: 11px;
    bottom: 1px;
    display: block;
    background: $error-red;
    border-radius: 50%;
    width: 0.625rem;
    height: 0.625rem;
  }
}

.copy-paste-text {
  background: lighten($ui-base-color, 4%);
  border-radius: 8px;
  border: 1px solid var(--background-border-color);
  padding: 16px;
  color: $primary-text-color;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: border-color 300ms linear;
  margin-bottom: 30px;

  &:focus,
  &.focused {
    transition: none;
    outline: 0;
    border-color: $highlight-text-color;
  }

  &.copied {
    border-color: $valid-value-color;
    transition: none;
  }

  textarea {
    width: 100%;
    height: auto;
    background: transparent;
    color: inherit;
    font: inherit;
    border: 0;
    padding: 0;
    margin-bottom: 30px;
    resize: none;

    &:focus {
      outline: 0;
    }
  }
}

.getting-started__wrapper {
  flex: 0 0 auto;
}

.flex-spacer {
  flex: 1 1 auto;
}

.getting-started {
  color: $dark-text-color;
  overflow: auto;
  border: 1px solid var(--background-border-color);
  border-top: 0;

  &__trends {
    flex: 0 1 auto;
    opacity: 1;
    animation: fade 150ms linear;
    margin-top: 10px;

    h4 {
      border-bottom: 1px solid var(--background-border-color);
      padding: 10px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;

      a {
        color: $darker-text-color;
        text-decoration: none;
      }
    }

    .trends__item {
      border-bottom: 0;
      padding: 10px;

      &__current {
        color: $darker-text-color;
      }
    }
  }
}

.keyboard-shortcuts {
  padding: 8px 0 0;
  overflow: hidden;

  thead {
    position: absolute;
    inset-inline-start: -9999px;
  }

  td {
    padding: 0 10px 8px;
  }

  kbd {
    display: inline-block;
    padding: 3px 5px;
    background-color: lighten($ui-base-color, 8%);
    border: 1px solid darken($ui-base-color, 4%);
  }
}

.timeline-hint {
  text-align: center;
  color: $dark-text-color;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  line-height: 21px;

  strong {
    font-weight: 500;
  }

  a {
    color: $highlight-text-color;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      color: lighten($highlight-text-color, 4%);
    }
  }

  &--with-descendants {
    border-top: 1px solid var(--background-border-color);
  }
}

.dropdown--active .emoji-button img {
  opacity: 1;
  filter: none;
}

.icon-badge-wrapper {
  position: relative;
}

.icon-badge {
  position: absolute;
  display: block;
  inset-inline-end: -0.25em;
  top: -0.25em;
  background-color: $ui-highlight-color;
  border-radius: 50%;
  font-size: 75%;
  width: 1em;
  height: 1em;
}

.ui .flash-message {
  margin-top: 10px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin-bottom: 0;
  min-width: 75%;
}

noscript {
  text-align: center;

  img {
    width: 200px;
    opacity: 0.5;
    animation: flicker 4s infinite;
  }

  div {
    font-size: 14px;
    margin: 30px auto;
    color: $secondary-text-color;
    max-width: 400px;

    a {
      color: $highlight-text-color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.copypaste {
  display: flex;
  align-items: center;
  gap: 10px;

  input {
    display: block;
    font-family: inherit;
    background: darken($ui-base-color, 8%);
    border: 1px solid $highlight-text-color;
    color: $darker-text-color;
    border-radius: 4px;
    padding: 6px 9px;
    line-height: 22px;
    font-size: 14px;
    transition: border-color 300ms linear;
    flex: 1 1 auto;
    overflow: hidden;

    &:focus {
      outline: 0;
      background: darken($ui-base-color, 4%);
    }
  }

  .button {
    flex: 0 0 auto;
    transition: background 300ms linear;
  }

  &.copied {
    input {
      border: 1px solid $valid-value-color;
      transition: none;
    }

    .button {
      background: $valid-value-color;
      transition: none;
    }
  }
}
