.verified-badge {
  display: inline-flex;
  align-items: center;
  color: $valid-value-color;
  gap: 4px;
  white-space: nowrap;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    color: inherit;
    font-weight: 500;
    text-decoration: none;
  }

  .icon {
    width: 13px;
    height: 13px;
  }
}
