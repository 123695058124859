.announcements__item__content {
  word-wrap: break-word;
  overflow-y: auto;

  .emojione {
    width: 20px;
    height: 20px;
    margin: -3px 0 0;
  }

  p {
    margin-bottom: 10px;
    white-space: pre-wrap;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $secondary-text-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &.mention {
      &:hover {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }
    }

    &.unhandled-link {
      color: $highlight-text-color;
    }
  }
}

.announcements {
  background: lighten($ui-base-color, 8%);
  font-size: 13px;
  display: flex;
  align-items: flex-end;

  &__mastodon {
    width: 124px;
    flex: 0 0 auto;

    @media screen and (max-width: 124px + 300px) {
      display: none;
    }
  }

  &__container {
    width: calc(100% - 124px);
    flex: 0 0 auto;
    position: relative;

    @media screen and (max-width: 124px + 300px) {
      width: 100%;
    }
  }

  &__item {
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
    position: relative;
    font-size: 15px;
    line-height: 20px;
    word-wrap: break-word;
    font-weight: 400;
    max-height: 50vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &__range {
      display: block;
      font-weight: 500;
      margin-bottom: 10px;
      padding-inline-end: 18px;
    }

    &__unread {
      position: absolute;
      top: 19px;
      inset-inline-end: 19px;
      display: block;
      background: $highlight-text-color;
      border-radius: 50%;
      width: 0.625rem;
      height: 0.625rem;
    }

    // Polyam: Show empty bar in announcements
    .reactions-bar--empty {
      display: flex;
    }
  }

  // Polyam: Added display flex to align arrows better in FF, Chrome still slightly misaligned
  &__pagination {
    padding: 15px;
    color: $darker-text-color;
    position: absolute;
    bottom: 3px;
    inset-inline-end: 0;
    display: flex;

    // Polyam: Fix arrow size in announcements
    .icon {
      width: 13px;
      height: 13px;
    }
  }
}

.layout-multiple-columns .announcements__mastodon {
  display: none;
}

.layout-multiple-columns .announcements__container {
  width: 100%;
}

.reactions-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
  margin-inline-start: -2px;
  width: calc(100% - (90px - 33px));

  &__item {
    flex-shrink: 0;
    background: lighten($ui-base-color, 12%);
    color: $darker-text-color;
    border: 0;
    border-radius: 3px;
    margin: 2px;
    cursor: pointer;
    user-select: none;
    padding: 0 6px;
    display: flex;
    align-items: center;
    transition: all 100ms ease-in;
    transition-property: background-color, color;

    &__emoji {
      display: block;
      margin: 3px 0;
      width: 16px;
      height: 16px;

      img {
        display: block;
        margin: 0;
        width: 100%;
        height: 100%;
        min-width: auto;
        min-height: auto;
        vertical-align: bottom;
        object-fit: contain;
      }
    }

    &__count {
      display: block;
      min-width: 9px;
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      margin-inline-start: 6px;
    }

    &:hover,
    &:focus,
    &:active {
      background: lighten($ui-base-color, 16%);
      transition: all 200ms ease-out;
      transition-property: background-color, color;
      color: lighten($darker-text-color, 4%);
    }

    &.active {
      transition: all 100ms ease-in;
      transition-property: background-color, color;
      background-color: mix(
        lighten($ui-base-color, 12%),
        $ui-highlight-color,
        80%
      );
      color: lighten($highlight-text-color, 8%);
    }
  }

  .emoji-picker-dropdown {
    margin: 2px;
  }

  &:hover .emoji-button {
    opacity: 0.85;
  }

  .emoji-button {
    color: $darker-text-color;
    margin: 0;
    font-size: 16px;
    width: auto;
    flex-shrink: 0;
    padding: 0 6px;
    height: 22px;
    display: flex;
    align-items: center;
    opacity: 0.5;
    transition: all 100ms ease-in;
    transition-property: background-color, color;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
      color: lighten($darker-text-color, 4%);
      transition: all 200ms ease-out;
      transition-property: background-color, color;
    }
  }

  &--empty {
    display: none; // Polyam: hide by default

    .emoji-button {
      padding: 0;
    }
  }
}
