.notification__relative_time {
  color: $dark-text-color;
  float: right;
  font-size: 14px;
  padding-bottom: 1px;
}

// Polyam: Split from class also containing .status__wrapper-direct
.notification-ungrouped--direct,
.notification-group--direct,
.notification-group--annual_report {
  @include private-toot-background;
}

// Polyam: Split from class also containing .status__wrapper-direct
.notification-ungrouped--direct {
  @include private-toot-header;
}

.notification__report {
  padding: 10px; // glitch: reduced padding
  border-bottom: 1px solid var(--background-border-color);
  display: flex;
  gap: 10px;

  &__avatar {
    flex: 0 0 auto;
  }

  &__details {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $darker-text-color;
    gap: 10px;
    font-size: 15px;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;

    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    strong {
      font-weight: 500;
    }
  }

  &__actions {
    flex: 0 0 auto;
  }
}

.notification-group--link {
  color: $secondary-text-color;
  text-decoration: none;

  .notification-group__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-grow: 1;
    font-size: 15px;
    line-height: 22px;

    strong,
    bdi {
      font-weight: 700;
    }

    .link-button {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
    }
  }
}

.notification__message {
  padding: 8px 14px; // glitch: reduced padding
  padding-bottom: 0;
  cursor: default;
  color: $darker-text-color;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;

  .icon {
    color: $highlight-text-color;
    width: 18px;
    height: 18px;
  }

  .icon-star {
    color: $gold-star;
  }

  > span {
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.notification__display-name {
  color: inherit;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.scrollable > div > :first-child .notification__dismiss-overlay > .wrappy {
  border-top: 1px solid $ui-base-color;
}

.notification__dismiss-overlay {
  overflow: hidden;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: -1px;
  padding-inline-start: 15px; // space for the box shadow to be visible
  z-index: 999;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  display: flex;

  .wrappy {
    width: $dismiss-overlay-width;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: lighten($ui-base-color, 8%);
    border-inline-start: 1px solid lighten($ui-base-color, 20%);
    box-shadow: 0 0 5px black;
    border-bottom: 1px solid $ui-base-color;
  }

  .ckbox {
    border: 2px solid $ui-primary-color;
    border-radius: 2px;
    width: 30px;
    height: 30px;
    font-size: 20px;
    color: $darker-text-color;
    text-shadow: 0 0 5px black;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:focus {
    outline: 0 !important;

    .ckbox {
      box-shadow: 0 0 1px 1px $ui-highlight-color;
    }
  }
}

.notif-cleaning {
  .status,
  .notification {
    padding-inline-end: ($dismiss-overlay-width + 0.5rem);
  }
}

// Polyam: Split from .notification__filter-bar, .account__section-headline
.notification__filter-bar {
  @include filter-bar;
}

// Polyam: Split from .notification, .status__wrapper, .conversation
.notification {
  @include notification-status-wrapper;
}

.notifications-permission-banner {
  padding: 30px;
  border-bottom: 1px solid var(--background-border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &__close {
    position: absolute;
    top: 10px;
    inset-inline-end: 10px;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    text-align: center;
  }

  p {
    color: $darker-text-color;
    margin-bottom: 15px;
    text-align: center;

    // Polyam: Reduced icon size
    .icon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }
}

// Toast

.notification-list {
  position: fixed;
  bottom: 2rem;
  inset-inline-start: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.notification-bar {
  flex: 0 0 auto;
  position: relative;
  inset-inline-start: -100%;
  width: auto;
  padding: 15px;
  margin: 0;
  color: $white;
  background: rgba($black, 0.85);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(lighten($classic-base-color, 4%), 0.85);
  border-radius: 8px;
  box-shadow:
    0 10px 15px -3px rgba($base-shadow-color, 0.25),
    0 4px 6px -4px rgba($base-shadow-color, 0.25);
  cursor: default;
  font-size: 15px;
  line-height: 21px;

  &.notification-bar-active {
    inset-inline-start: 1rem;
  }

  .no-reduce-motion & {
    transition: 0.5s cubic-bezier(0.89, 0.01, 0.5, 1.1);
    transform: translateZ(0);
  }
}

.notification-bar-title {
  margin-inline-end: 5px;
}

.notification-bar-title,
.notification-bar-action {
  font-weight: 700;
}

.notification-bar-action {
  text-transform: uppercase;
  margin-inline-start: 10px;
  cursor: pointer;
  color: $blurple-300;
  border-radius: 4px;
  padding: 0 4px;

  &:hover,
  &:focus,
  &:active {
    background: rgba($ui-base-color, 0.85);
  }
}

.filtered-notifications-banner {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--background-border-color);
  padding: 16px 24px;
  gap: 8px;
  color: $darker-text-color;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: $secondary-text-color;
  }

  .notification-group__icon {
    color: inherit;
  }

  &__text {
    flex: 1 1 auto;
    font-size: 14px;
    line-height: 20px;

    strong {
      font-size: 16px;
      line-height: 24px;
      display: block;
    }
  }

  &__badge {
    background: $ui-button-background-color;
    color: $white;
    border-radius: 100px;
    padding: 2px 8px;
  }
}

.notification-request {
  $padding: 15px;

  display: flex;
  padding: $padding;
  gap: 8px;
  position: relative;
  border-bottom: 1px solid var(--background-border-color);

  &__checkbox {
    position: absolute;
    inset-inline-start: $padding;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    overflow: hidden;
    opacity: 0;

    .check-box {
      display: flex;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1 1 auto;
    text-decoration: none;
    color: inherit;
    overflow: hidden;

    .account__avatar {
      flex-shrink: 0;
    }
  }

  &__name {
    flex: 1 1 auto;
    color: $darker-text-color;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;

    &__display-name {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 24px;
      color: $secondary-text-color;

      bdi {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .filtered-notifications-banner__badge {
      background: $ui-button-background-color;
      border-radius: 4px;
      padding: 1px 6px;
      color: $white;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;

    .icon-button {
      border-radius: 4px;
      border: 1px solid var(--background-border-color);
      padding: 5px;
    }
  }

  .notification-request__link {
    transition: padding-inline-start 0.1s ease-in-out;
  }

  &--forced-checkbox {
    cursor: pointer;

    &:hover {
      background: var(--on-surface-color);
    }

    .notification-request__checkbox {
      opacity: 1;
      width: 30px;
    }

    .notification-request__link {
      padding-inline-start: 30px;
    }

    .notification-request__actions {
      display: none;
    }
  }
}

.notification-group {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 10px 14px; // glitch: reduced padding
  border-bottom: 1px solid var(--background-border-color);

  &__icon {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    color: $dark-text-color;

    // Polyam: reduced icon size
    .icon {
      width: 24px;
      height: 24px;
    }
  }

  &--follow &__icon,
  &--follow-request &__icon {
    color: $highlight-text-color;
  }

  &--favourite &__icon {
    color: $gold-star;
  }

  &--reblog &__icon {
    color: $valid-value-color;
  }

  &--relationships-severance-event &__icon,
  &--admin-report &__icon,
  &--admin-sign-up &__icon,
  &--admin-report-note &__icon {
    color: $dark-text-color;
  }

  &--moderation-warning &__icon {
    color: $red-bookmark;
  }

  &--follow-request &__actions {
    align-items: center;
    display: flex;
    gap: 8px;

    .icon-button {
      border: 1px solid var(--background-border-color);
      border-radius: 50%;
      padding: 1px;

      // Polyam: Match upstream's button size
      .icon {
        padding: 3px;
      }
    }
  }

  // Polyam: Style for icon buttons
  &--follow &__actions {
    @include follow-unfollow-icons;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1 auto;
    overflow: hidden;
    container-type: inline-size;

    // Polyam: Removed @container here as timestamps should remain visible

    &__header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%; // Polyam: Consistent timestamp in report/report-note

      &__wrapper {
        display: flex;
        justify-content: space-between;
      }

      &__label {
        display: flex;
        gap: 8px;
        font-size: 15px;
        line-height: 22px;
        color: $darker-text-color;
        justify-content: space-between; // Polyam: Consistent timestamps

        a {
          color: inherit;
          text-decoration: none;
        }

        bdi {
          font-weight: 700;
          color: $primary-text-color;
        }

        time {
          color: $dark-text-color;
        }
      }
    }

    &__status {
      border: 1px solid var(--background-border-color);
      border-radius: 8px;
      padding: 8px;
    }

    &__additional-content {
      color: $dark-text-color;
      margin-top: -8px; // to offset the parent's `gap` property
      font-size: 15px;
      line-height: 22px;
    }
  }

  &__avatar-group {
    display: flex;
    gap: 8px;
    height: 28px;
    overflow-y: hidden;
    flex-wrap: wrap;
  }

  .status {
    padding: 0;
    border: 0;
  }

  &__embedded-status {
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;

    &__account {
      display: flex;
      align-items: center;
      gap: 4px;
      color: $dark-text-color;
      font-size: 15px;
      line-height: 22px;

      bdi {
        color: $darker-text-color;
      }
    }

    /* glitch: used for CWs */
    p {
      font-size: 15px;
      color: $darker-text-color;
    }

    &__content {
      display: -webkit-box;
      font-size: 15px;
      line-height: 22px;
      color: $darker-text-color;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      max-height: none;
      overflow: hidden;

      p,
      a {
        color: inherit;
      }

      p {
        margin-bottom: 8px;
      }
    }

    .reply-indicator__attachments {
      font-size: 15px;
      line-height: 22px;
      color: $dark-text-color;
    }
  }
}

// Polyam: Upstream also has .compose-form__actions here
.notification-group__actions {
  @include compose-action-button;
}

.notification-ungrouped {
  padding: 16px 14px; // glitch: reduced padding
  border-bottom: 1px solid var(--background-border-color);

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $dark-text-color;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    padding-inline-start: 24px;
    margin-bottom: 16px;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;

      .icon {
        width: 16px;
        height: 16px;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .status {
    border: 0;
    padding: 0;

    &__avatar {
      width: 40px;
      height: 40px;
    }
  }

  .status__wrapper-direct {
    background: transparent;
  }

  $icon-margin: 48px; // 40px avatar + 8px gap

  // Polyam: Removed margin and restricted width of elements here

  .more-from-author {
    width: calc(100% - $icon-margin + 2px);
  }

  .status__content__read-more-button {
    margin-inline-start: $icon-margin;
  }

  .notification__report {
    border: 0;
    padding: 0;
  }
}

.notification-group--unread,
.notification-ungrouped--unread {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    border-inline-start: 4px solid $highlight-text-color;
    pointer-events: none;
  }
}
