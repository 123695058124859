input.glitch-setting-text {
  box-sizing: border-box;
  color: $darker-text-color;
  background: transparent;
  border: 0;
  border-bottom: 2px solid $ui-primary-color;
  outline: 0;
  font-family: inherit;
  margin-bottom: 10px;
  padding: 7px 0;
  width: 100%;

  &:focus,
  &:active {
    color: $primary-text-color;
    border-bottom-color: $ui-highlight-color;
  }

  @media screen and (width <= 600px) {
    font-size: 16px;
  }
}

.setting-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.setting-toggle__label {
  color: $darker-text-color;
}

.column-settings__pillbar {
  display: flex;
  overflow: hidden;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  margin-bottom: 10px;
  align-items: stretch;
  gap: 2px;
}

.column-settings {
  display: flex;
  flex-direction: column;

  &__section {
    // FIXME: Legacy
    color: $darker-text-color;
    cursor: default;
    display: block;
    font-weight: 500;
  }

  .column-header__links {
    margin: 0;
  }

  section {
    padding: 16px;
    border-bottom: 1px solid var(--background-border-color);

    &:last-child {
      border-bottom: 0;
    }
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    font-weight: 500;
    color: $primary-text-color;
    margin-bottom: 16px;
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .app-form__toggle {
    &__toggle > div {
      border: 0;
    }
  }
}

.column-settings__hashtags {
  margin-top: 15px;

  .column-settings__row {
    margin-bottom: 15px;
  }

  .column-select {
    &__control {
      @include search-input;

      &::placeholder {
        color: lighten($darker-text-color, 4%);
      }

      &::-moz-focus-inner {
        border: 0;
      }

      &::-moz-focus-inner,
      &:focus,
      &:active {
        outline: 0 !important;
      }

      &:focus {
        background: lighten($ui-base-color, 4%);
      }

      @media screen and (width <= 600px) {
        font-size: 16px;
      }
    }

    &__placeholder {
      color: $dark-text-color;
      padding-inline-start: 2px;
      font-size: 12px;
    }

    &__value-container {
      padding-inline-start: 6px;
    }

    &__multi-value {
      background: lighten($ui-base-color, 8%);

      &__remove {
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          background: lighten($ui-base-color, 12%);
          color: lighten($darker-text-color, 4%);
        }
      }
    }

    &__multi-value__label,
    &__input,
    &__input-container {
      color: $darker-text-color;
    }

    &__clear-indicator,
    &__dropdown-indicator {
      cursor: pointer;
      transition: none;
      color: $dark-text-color;

      &:hover,
      &:active,
      &:focus {
        color: lighten($dark-text-color, 4%);
      }
    }

    &__indicator-separator {
      background-color: lighten($ui-base-color, 8%);
    }

    &__menu {
      @include search-popout;

      & {
        padding: 0;
        background: $ui-secondary-color;
      }
    }

    &__menu-list {
      padding: 6px;
    }

    &__option {
      color: $inverted-text-color;
      border-radius: 4px;
      font-size: 14px;

      &--is-focused,
      &--is-selected {
        background: darken($ui-secondary-color, 10%);
      }
    }
  }
}

.column-settings__row {
  .text-btn:not(.column-header__permission-btn) {
    margin-bottom: 15px;
  }
}

.column-settings__row .radio-button {
  display: flex;
}
