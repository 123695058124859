.picture-in-picture {
  position: fixed;
  bottom: 20px;
  inset-inline-end: 20px;
  width: 300px;

  // glitch: feature to chose which side the pop-in player is displayed
  &.left {
    inset-inline-end: unset;
    inset-inline-start: 20px;
  }

  &__footer {
    border-radius: 0 0 4px 4px;
    background: lighten($ui-base-color, 4%);
    padding: 10px;
    padding-top: 12px;
    display: flex;
    justify-content: space-between;
  }

  &__header {
    border-radius: 4px 4px 0 0;
    background: lighten($ui-base-color, 4%);
    padding: 10px;
    display: flex;
    justify-content: space-between;

    &__account {
      display: flex;
      text-decoration: none;
      overflow: hidden;
    }

    .account__avatar {
      margin-inline-end: 10px;
    }

    .display-name {
      color: $primary-text-color;
      text-decoration: none;

      strong,
      span {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      span {
        color: $darker-text-color;
      }
    }
  }

  .video-player,
  .audio-player {
    border-radius: 0;
  }
}

.picture-in-picture-placeholder {
  box-sizing: border-box;
  border: 2px dashed var(--background-border-color);
  background: $base-shadow-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: $darker-text-color;
  aspect-ratio: 16 / 9;

  .icon {
    width: 21px;
    height: 21px;
    margin-bottom: 10px;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: lighten($ui-base-color, 12%);
  }
}
