.status-card {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  color: $darker-text-color;
  margin-top: 14px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid var(--background-border-color);
  border-radius: 8px;
  contain: inline-size layout paint style;

  &.bottomless {
    border-radius: 8px 8px 0 0;
  }

  &__actions {
    bottom: 0;
    inset-inline-start: 0;
    position: absolute;
    inset-inline-end: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > div {
      background: rgba($base-shadow-color, 0.6);
      border-radius: 8px;
      padding: 12px 9px;
      backdrop-filter: blur(10px) saturate(180%) contrast(75%) brightness(70%);
      flex: 0 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button,
    a {
      display: inline;
      color: $secondary-text-color;
      background: transparent;
      border: 0;
      padding: 0 8px;
      text-decoration: none;
      font-size: 18px;
      line-height: 18px;

      &:hover,
      &:active,
      &:focus {
        color: $primary-text-color;
      }
    }

    a {
      font-size: 19px;
      position: relative;
      bottom: -1px;
    }
  }
}

a.status-card {
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    // Polyam: Fix underline being white
    text-decoration-color: $highlight-text-color;

    .status-card__title,
    .status-card__host,
    .status-card__author,
    .status-card__description {
      color: $highlight-text-color;
    }
  }
}

.status-card a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    .status-card__title,
    .status-card__host,
    .status-card__author,
    .status-card__description {
      color: $highlight-text-color;
    }
  }
}

.status-card-photo {
  cursor: zoom-in;
  display: block;
  text-decoration: none;
  width: 100%;
  height: auto;
  margin: 0;
}

.status-card-video {
  // Firefox has a bug where frameborder=0 iframes add some extra blank space
  // see https://bugzilla.mozilla.org/show_bug.cgi?id=155174
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.status-card__title {
  display: block;
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  color: $primary-text-color;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.status-card.expanded .status-card__title {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.status-card__content {
  flex: 1 1 auto;
  overflow: hidden;
  padding: 8px 10px; // glitch: reduced padding
  box-sizing: border-box;
  max-width: 100%;
}

.status-card__host {
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-card__author {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  color: $primary-text-color;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  strong {
    font-weight: 500;
  }
}

.status-card__description {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-card__image {
  flex: 0 0 auto;
  width: 96px; // glitch: intentional change to better use space
  aspect-ratio: 1;
  background: lighten($ui-base-color, 8%);
  position: relative;

  & > .icon {
    width: 15px; // Polyam: Reduced icon size
    height: 15px; // Polyam: Reduced icon size
    position: absolute;
    transform-origin: 50% 50%;
    top: 50%;
    inset-inline-start: 50%;
    transform: translate(-50%, -50%);
  }
}

.status-card__image-image {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
}

.status-card__image-preview {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  z-index: 0;
  background: $base-overlay-background;

  &--hidden {
    display: none;
  }
}

.status-card.expanded {
  flex-direction: column;
  align-items: flex-start;
}

.status-card.expanded .status-card__image {
  width: 100%;
  aspect-ratio: auto;
}

.status-card__image,
.status-card__image-image,
.status-card__image-preview {
  border-start-start-radius: 8px;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: 8px;
}

.status-card.expanded .status-card__image,
.status-card.expanded .status-card__image-image,
.status-card.expanded .status-card__image-preview {
  border-start-end-radius: 8px;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.status-card.bottomless .status-card__image,
.status-card.bottomless .status-card__image-image,
.status-card.bottomless .status-card__image-preview {
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.status-card.expanded > a {
  width: 100%;
}

.more-from-author {
  box-sizing: border-box;
  font-size: 14px;
  color: $darker-text-color;
  background: var(--surface-background-color);
  border: 1px solid var(--background-border-color);
  border-top: 0;
  border-radius: 0 0 8px 8px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 8px;

  .logo {
    width: 16px;
    height: 16px;
    color: $darker-text-color;
  }

  & > span {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  a {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    font-weight: 500;
    color: $primary-text-color;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      color: $highlight-text-color;
    }
  }
}
