.content-warning {
  display: block;
  box-sizing: border-box;
  background: rgba($ui-highlight-color, 0.05);
  color: $secondary-text-color;
  border: 1px solid rgba($ui-highlight-color, 0.15);
  border-radius: 8px;
  padding: 8px (5px + 8px);
  position: relative;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;

  p {
    margin-bottom: 8px;
    font-weight: 500;
  }

  .link-button {
    font-size: inherit;
    line-height: inherit;
    font-weight: 500;
  }

  &--filter {
    color: $darker-text-color;

    p {
      font-weight: normal;
    }

    .filter-name {
      font-weight: 500;
      color: $secondary-text-color;
    }
  }

  .status__content__spoiler-icon {
    float: inline-end;
    width: 18px; // Polyam: Reduced icon size
    height: 18px; // Polyam: Reduced icon size
  }
}
