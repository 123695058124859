.autosuggest-textarea {
  &__textarea {
    background: transparent;
    min-height: 100px;
    padding-bottom: 0;
    resize: none;
    scrollbar-color: initial;

    &::-webkit-scrollbar {
      all: unset;
    }
  }

  &__suggestions {
    box-shadow: var(--dropdown-shadow);
    background: var(--input-background-color);
    border: 1px solid var(--background-border-color);
    border-radius: 0 0 4px 4px;
    color: var(--on-input-color);
    font-size: 14px;
    padding: 0;

    &__item {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 48px;
      cursor: pointer;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: var(--on-input-color);

      &:last-child {
        border-radius: 0 0 4px 4px;
      }

      &:hover,
      &:focus,
      &:active {
        background: var(--dropdown-border-color);

        .autosuggest-account .display-name__account {
          color: inherit;
        }
      }

      &.selected {
        background: $ui-highlight-color;
        color: $ui-button-color;

        .autosuggest-account .display-name__account {
          color: inherit;
        }
      }
    }
  }
}

.autosuggest-account,
.autosuggest-emoji,
.autosuggest-hashtag {
  flex: 1 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 8px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autosuggest-account {
  .display-name {
    font-weight: 400;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
  }

  .display-name__account {
    display: block;
    line-height: 16px;
    font-size: 12px;
    color: $ui-primary-color;
  }
}

.autosuggest-hashtag {
  justify-content: space-between;

  &__name {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__uses {
    flex: 0 0 auto;
    text-align: end;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.autosuggest-emoji {
  &__name {
    flex: 1 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.autosuggest-account .account__avatar,
.autosuggest-emoji img {
  display: block;
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
}
