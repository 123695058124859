.account-anniversary-banner,
.moved-account-banner,
.follow-request-banner,
.account-memorial-banner {
  padding: 20px;
  background: var(--surface-background-color);
  display: flex;
  align-items: center;
  flex-direction: column;

  &__message {
    color: $darker-text-color;
    padding: 8px 0;
    padding-top: 0;
    padding-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
  }

  &__action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    width: 100%;
  }

  .detailed-status__display-name {
    margin-bottom: 0;
  }
}

.follow-request-banner .button {
  width: 100%;
}

.account-anniversary-banner__message,
.account-memorial-banner__message {
  margin-bottom: 0;
}

.account-anniversary-banner {
  position: relative;
  z-index: -2;
}

// Polyam: Animation for anniversary-note
@keyframes fall {
  0% {
    transform: translateY(-220%) rotate(0);
  }

  100% {
    transform: translateY(220%) rotate(180deg);
  }
}

.no-reduce-motion {
  .fedimation {
    .fedi-logo {
      animation: fall 5s linear infinite;

      &:nth-child(even) {
        animation-delay: 2.5s;
      }
    }
  }
}

.fedimation {
  display: flex;
  justify-content: space-between;
  user-select: none;
  pointer-events: none;
  z-index: -1;
  width: 100%;
  height: 0;

  .fedi-logo {
    width: 18px;
    height: 18px;
    background-image: url('~flavours/polyam/images/fediverse.svg');
    background-size: contain;
    transform: translateY(-220%);
  }
}
