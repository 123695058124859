.status__content--with-action {
  cursor: pointer;
}

.status__content {
  clear: both;
}

// Polyam: Split out from .status__content, .edit-indicator__content, .reply-indicator__content upstream
.status__content {
  @include status-content;
}

.status__content.status__content--collapsed {
  max-height: 20px * 15; // 15 lines is roughly above 500 characters
}

.status__content__read-more-button,
.status__content__translate-button {
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 22px;
  color: $highlight-text-color;
  border: 0;
  background: transparent;
  padding: 0;
  padding-top: 16px;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }

  .icon {
    width: 15px;
    height: 15px;
  }
}

.translate-button {
  margin-top: 16px;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  color: $dark-text-color;
}

.status__wrapper--filtered {
  color: $dark-text-color;
  border: 0;
  font-size: inherit;
  text-align: center;
  line-height: inherit;
  margin: 0;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  clear: both;
  border-bottom: 1px solid var(--background-border-color);

  &__button {
    display: inline;
    color: lighten($ui-highlight-color, 8%);
    border: 0;
    background: transparent;
    padding: 0;
    font-size: inherit;
    line-height: inherit;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}

.focusable {
  &:focus {
    outline: 0;
    background: var(--toot-focus-background-color);
  }
}

.status {
  padding: 10px 14px; // glitch: reduced padding
  min-height: 54px;
  border-bottom: 1px solid var(--background-border-color);
  cursor: auto;
  opacity: 1;
  animation: fade 150ms linear;

  @keyframes fade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .content-warning {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .media-gallery,
  .video-player,
  .audio-player,
  .attachment-list {
    margin-top: 8px; // glitch: reduced margins
  }

  &.light {
    .status__relative-time,
    .status__visibility-icon {
      color: $light-text-color;
    }

    .status__display-name {
      color: $inverted-text-color;
    }

    .display-name {
      color: $light-text-color;

      strong {
        color: $inverted-text-color;
      }
    }

    .status__content {
      color: $inverted-text-color;

      a {
        color: $highlight-text-color;
      }

      &__spoiler-link {
        color: $primary-text-color;
        background: $ui-primary-color;

        &:hover,
        &:focus {
          background: lighten($ui-primary-color, 8%);
        }
      }
    }
  }

  &--in-thread {
    $thread-margin: 46px + 10px;

    border-bottom: 0;

    // Polyam: Reactions bar
    .status__content,
    .status__action-bar,
    .reactions-bar,
    .media-gallery,
    .video-player,
    .audio-player,
    .attachment-list,
    .picture-in-picture-placeholder,
    .more-from-author,
    .status-card,
    .hashtag-bar,
    .content-warning,
    .filter-warning {
      margin-inline-start: $thread-margin;
      width: calc(100% - $thread-margin);
    }

    .more-from-author {
      width: calc(100% - $thread-margin + 2px);
    }

    .status__content__read-more-button {
      margin-inline-start: $thread-margin;
    }
  }

  &__action-bar__button-wrapper {
    flex-basis: 0;

    // glitch-soc: disable this for now; flex-grow: 1;

    &:last-child {
      flex-grow: 0;
    }
  }

  &--first-in-thread {
    border-top: 1px solid var(--background-border-color);
  }

  &__line {
    height: 10px - 4px;
    border-inline-start: 2px solid lighten($ui-base-color, 8%);
    width: 0;
    position: absolute;
    top: 0;
    inset-inline-start: 14px + ((46px - 2px) * 0.5);

    &--full {
      top: 0;
      height: 100%;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 10px - 4px;
        height: 46px + 4px + 4px;
        width: 2px;
        background: $ui-base-color;
        inset-inline-start: -2px;
      }
    }

    &--first {
      top: 10px + 46px + 4px;
      height: calc(100% - (10px + 46px + 4px));

      &::before {
        display: none;
      }
    }
  }
}

.status__relative-time {
  display: block;
  font-size: 14px;
  color: $dark-text-color;
  text-align: end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status__display-name {
  color: $dark-text-color;
}

.status__info .status__display-name {
  max-width: 100%;
  display: flex;
  font-size: 15px;
  line-height: 22px;
  align-items: center;
  gap: 10px;
  overflow: hidden;

  .display-name {
    bdi {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__account {
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.status__info {
  font-size: 15px;
  padding-bottom: 10px;
  display: flex;
  align-items: start; // glitch: changed because of our different layout
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
}

.status__info__icons {
  display: flex;
  align-items: center;
  height: 1em;
  color: $action-button-color;
  gap: 4px;

  // Polyam: Different class as otherwise doesn't apply to collapse icon
  .icon {
    width: 16px;
    height: 16px;
  }

  // Polyam: Temporary fix for language icon
  .text-icon {
    font-weight: 600;
  }
}

.status-check-box__status {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;

  .detailed-status__display-name {
    color: $dark-text-color;

    span {
      display: inline;
    }

    &:hover strong {
      text-decoration: none;
    }
  }

  .media-gallery,
  .audio-player,
  .video-player {
    margin-top: 15px;
    max-width: 250px;
  }

  .status__content {
    padding: 0;
    white-space: normal;
  }

  .media-gallery__item-thumbnail {
    cursor: default;
  }
}

.status__prepend {
  padding: 8px 14px; // glitch: reduced padding
  padding-bottom: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: $dark-text-color;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Polyam: Split from class also containing .notification-ungrouped--direct, .notification-group--direct, .notification-group--annual-report
.status__wrapper-direct {
  @include private-toot-background;
}

// Polyam: Split from class also containing .notification-ungrouped--direct
.status__wrapper-direct {
  @include private-toot-header;
}

.status__action-bar {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-top: 8px;

  &-spacer {
    flex-grow: 1;
  }

  // Polyam: Reaction button
  & > .emoji-picker-dropdown {
    width: 22px;
    height: 22px;
  }
}

.detailed-status__action-bar-dropdown {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.detailed-status {
  padding: 14px 10px; // glitch: reduced padding
  border-top: 1px solid var(--background-border-color);

  .status__content {
    font-size: 19px;
    line-height: 24px;

    .emojione {
      width: 24px;
      height: 24px;
      margin: -1px 0 0;
    }
  }

  .media-gallery,
  .video-player,
  .audio-player {
    margin-top: 8px; // glitch: reduced margins
  }

  .status__prepend {
    padding: 0;
    margin-bottom: 16px;
  }

  .content-warning {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .logo {
    width: 40px;
    height: 40px;
    color: $dark-text-color;
  }
}

.embed {
  position: relative;

  &__overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .detailed-status {
    border-top: 0;
  }
}

.scrollable > div:first-child .detailed-status {
  border-top: 0;
}

.detailed-status__meta {
  margin-top: 24px;
  color: $dark-text-color;
  font-size: 14px;
  line-height: 18px;

  &__line {
    border-bottom: 1px solid var(--background-border-color);
    padding: 8px 0;
    display: flex;
    align-items: center;
    gap: 8px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  // Polyam: Different icon size as large icons look weird
  .icon {
    width: 14px;
    height: 14px;
  }

  .animated-number {
    color: $secondary-text-color;
    font-weight: 500;
  }
}

.detailed-status__action-bar {
  border-top: 1px solid var(--background-border-color);
  border-bottom: 1px solid var(--background-border-color);
  display: flex;
  flex-direction: row;
  padding: 8px 0; // glitch: reduced padding
}

.detailed-status__wrapper-direct {
  .detailed-status,
  .detailed-status__action-bar {
    background: mix($ui-base-color, $ui-highlight-color, 95%);
  }

  &:focus {
    .detailed-status,
    .detailed-status__action-bar {
      background: mix(lighten($ui-base-color, 4%), $ui-highlight-color, 95%);
    }
  }

  .detailed-status__action-bar {
    border-top-color: mix(
      lighten($ui-base-color, 8%),
      $ui-highlight-color,
      95%
    );
  }

  .status__prepend {
    color: $highlight-text-color;
  }
}

.detailed-status__link {
  display: inline-flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  gap: 6px;
}

// Polyam: Split from class also containing .account__display-name
.status__display-name,
.status__relative-time,
.detailed-status__display-name,
.detailed-status__datetime,
.detailed-status__application {
  @include no-text-decoration;
}

// Polyam: Split from class also containing .account__display-name
.status__display-name {
  @include display-name-primary-color;
}

.muted {
  .emojione {
    opacity: 0.5;
  }
}

// Polyam: Split from class also containing a.account__display-name
.status__display-name,
.detailed-status__display-name {
  @include underline-display-name-on-hover;
}

.detailed-status__application,
.detailed-status__datetime {
  color: inherit;
}

.detailed-status__display-name {
  color: $darker-text-color;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 16px;
  overflow: hidden;

  strong,
  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  strong {
    color: $primary-text-color;
  }
}

.status__avatar {
  width: 46px;
  height: 46px;
}

.muted {
  .status__content,
  .status__content p,
  .status__content a,
  .status__content__text {
    color: $dark-text-color;
  }

  .status__display-name strong {
    color: $dark-text-color;
  }

  .status__avatar {
    opacity: 0.5;
  }
}

// Polyam: Split from .account--panel__button, .detailed-status__button
.detailed-status__button {
  @include panel-button;
}

.status__relative-time,
.detailed-status__datetime {
  &:hover {
    text-decoration: underline;
  }
}

// Polyam: Split from .notifaction, .status__wrapper
.status__wrapper {
  @include notification-status-wrapper;
}
