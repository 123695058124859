.scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;

  &.optionally-scrollable {
    overflow-y: auto;
  }

  @supports (display: grid) {
    // hack to fix Chrome <57
    contain: strict;
  }

  &--flex {
    display: flex;
    flex-direction: column;
  }

  &__append {
    flex: 1 1 auto;
    position: relative;
    min-height: 120px;
  }

  .scrollable {
    flex: 1 1 auto;
  }
}

.scrollable.fullscreen {
  @supports (display: grid) {
    // hack to fix Chrome <57
    contain: none;
  }
}
